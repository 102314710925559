/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import * as jwt from '@/services/jwt';
// eslint-disable-next-line camelcase
import router from '@/router';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    id: '',
    nombre: '',
    email: '',
    es_activo: '',
    authorized: false,
    es_proveedor: true,
    es_interno: false,
    es_repse: false,
    permisos: [],
    puede_cargar: false,
    fecha_limcarga: '',
    fecha_libcarga: '',
    image: '',
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      });
    },
  },
  actions: {
    LOGIN({ commit, dispatch }, { payload }) {
      const { user, password } = payload;

      commit('SET_STATE', {
        loading: true,
      });

      const { login } = jwt;

      login(user, password).then((success) => {
        if (success) {
          dispatch('LOAD_CURRENT_ACCOUNT');
          // Vue.prototype.$msg.success('Sesión iniciada exitosamente.');
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          });
        }
      }).catch(() => {
        commit('SET_STATE', {
          loading: false,
        });
      });
    },
    LOAD_CURRENT_ACCOUNT({ commit }) {
      commit('SET_STATE', {
        loading: true,
      });

      const tk = sessionStorage.getItem('session');
      if (tk) {
        const { currentAccount } = jwt;
        /*
        const { imageProfile } = jwt;
        let imgProf = '/assets/avatar.svg';
        */

        currentAccount().then((response) => {
          if (response) {
            const {
              UserId,
              Nombre,
              Email,
              EsActivo,
              EsProveedor,
              Interno,
              EsRepse,
              Permisos,
              PuedeCargar,
              FechaLimCarga,
              FechaLibCarga,
            } = response;
            commit('SET_STATE', {
              id: UserId,
              nombre: Nombre,
              email: Email,
              es_activo: EsActivo,
              authorized: true,
              es_proveedor: EsProveedor,
              es_interno: Interno,
              es_repse: EsRepse,
              permisos: Permisos,
              puede_cargar: PuedeCargar,
              fecha_limcarga: FechaLimCarga,
              fecha_libcarga: FechaLibCarga,
            });

            // Descomentar para cargar imagen de perfil desde api
            /*
            imageProfile().then((res) => {
              if (res) {
                imgProf = res;
              }

              commit('SET_STATE', {
                image: imgProf,
              });
            });
            */
          }

          commit('SET_STATE', {
            loading: false,
          });
        }).catch(() => {
          commit('SET_STATE', {
            loading: false,
          });
        });
      } else {
        commit('SET_STATE', {
          loading: false,
        });
      }
    },
    LOGOUT({ commit }) {
      const { logout } = jwt;
      logout().then(() => {
        commit('SET_STATE', {
          id: '',
          nombre: '',
          email: '',
          es_activo: '',
          authorized: false,
          es_proveedor: true,
          es_interno: false,
          es_repse: false,
          permisos: [],
          puede_cargar: false,
          fecha_limcarga: '',
          fecha_libcarga: '',
          image: '',
          loading: false,
        });
        router.push('/auth');
      });
    },
    NEW_IMAGE({ commit }, file) {
      commit('SET_STATE', {
        image: file,
      });
    },
    REGISTER({ commit, dispatch }, { payload }) {
      commit('SET_STATE', {
        loading: true,
      });

      const { register } = jwt;

      register(payload).then((success) => {
        if (success) {
          const { email, password } = payload;
          dispatch('LOGIN', { payload: { user: email, password } });
          // Vue.prototype.$msg.success('Sesión iniciada exitosamente.');
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          });
        }
      }).catch(() => {
        commit('SET_STATE', {
          loading: false,
        });
      });
    },
    FORGOT_PASSWORD({ commit /* , dispatch */ }, { payload }) {
      const { email } = payload;

      commit('SET_STATE', {
        loading: true,
      });

      const { forgotPassword } = jwt;

      forgotPassword(email).then((success) => {
        commit('SET_STATE', {
          loading: false,
        });
        if (success) {
          router.push('/auth');
        }
      }).catch(() => {
        commit('SET_STATE', {
          loading: false,
        });
      });
    },
    RESET_PASSWORD({ commit /* , dispatch */ }, { payload }) {
      const { token, password } = payload;

      commit('SET_STATE', {
        loading: true,
      });

      const { resetPassword } = jwt;

      resetPassword(token, password).then((success) => {
        commit('SET_STATE', {
          loading: false,
        });
        if (success) {
          router.push('/auth');
        }
      }).catch(() => {
        commit('SET_STATE', {
          loading: false,
        });
      });
    },
  },
  getters: {
    // user: state => state,
  },
};
