/* eslint-disable import/no-cycle */
import router from '@/router';
import apiClient from '../axios';

export async function login(user, password) {
  return apiClient.post('/login', { email: user, password })
    .then((response) => {
      if (response) {
        const token = response.data.access_token;
        if (token) {
          sessionStorage.setItem('session', token);
        }
        return response.data;
      }
      return false;
    }).catch((err) => {
      if (err.response.status === 406) {
        router.push({ path: '/406' });
      } else if (err.response.status === 503) {
        router.push({ path: '/503' });
      }
    });
}

export async function currentAccount() {
  return apiClient
    .get('/users/cuenta')
    .then((response) => {
      if (response) {
        const session = sessionStorage.getItem('session');
        if (session) {
          return response.data;
        }
      }
      return false;
    })
    .catch(() => {
    });
}

export async function imageProfile() {
  const config = {
    responseType: 'blob',
  };

  return apiClient
    .get('/users/image', config)
    .then((response) => {
      if (response) {
        const session = sessionStorage.getItem('session');
        if (session) {
          const imgProf = URL.createObjectURL(response.data);
          URL.revokeObjectURL(response.data);

          return imgProf;
        }
      }
      return false;
    })
    .catch(() => {
    });
}

export async function logout() {
  return apiClient
    .post('/logout')
    .then(() => {
      // Codigo para remover cualquier llave con nombre inicial session
      const sessionKeys = Object.keys(sessionStorage).filter((Key) => Key.includes('session'));
      sessionKeys.forEach((k) => {
        sessionStorage.removeItem(k);
      });

      return true;
    })
    .catch(() => {
    });
}

export async function getPermisos() {
  return apiClient
    .get('/users/permisos')
    .then((response) => {
      if (response) {
        return response.data;
      }
      return false;
    })
    .catch(() => {
    });
}

export async function register(payload) {
  return apiClient.post('/register', payload)
    .then((response) => {
      if (response) {
        return true;
      }
      return false;
    }).catch(() => {
    });
}

export async function forgotPassword(email) {
  return apiClient.post('/forgot-password', { email })
    .then((response) => {
      if (response) {
        return true;
      }
      return false;
    }).catch((err) => {
      if (err.response.status === 406) {
        router.push({ path: '/406' });
      } else if (err.response.status === 503) {
        router.push({ path: '/503' });
      }
    });
}

export async function resetPassword(token, password) {
  return apiClient.post('/reset-password', { token, password })
    .then((response) => {
      if (response) {
        return true;
      }
      return false;
    }).catch((err) => {
      if (err.response.status === 406) {
        router.push({ path: '/406' });
      } else if (err.response.status === 503) {
        router.push({ path: '/503' });
      }
    });
}
